import React from 'react';

const KeypadNumber = ({ number, onClick }) => (
  <div className="keypad-number">
    <div>
      <span className="number" onClick={() => onClick(number)}>{number}</span>
    </div>
  </div>
);

export default KeypadNumber;
